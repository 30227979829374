/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
|
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

document.addEventListener( "DOMContentLoaded", function() {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect browser and device
  detectBrowserAndDevice();

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // Phone Analytics Tracking
  jQuery('a[href^="tel:"]').click(function(){gtag('event','phonecall', {'event_category' : 'contact', 'event_label' : 'Phone Link Click'});});
  // Email Analytics Tracking
  jQuery('a[href^="mailto:"]').click(function(){gtag('event','email', {'event_category' : 'contact', 'event_label' : 'Email Link Click'});});
  // Appointment Request Analytics Tracking
  jQuery(document).on('gform_confirmation_loaded', function(event, formId) {if (formId == 1) {gtag('event','form', {'event_category' : 'contact', 'event_label' : 'Appointment Request'});}});

  var pages_need_tns = [".page-template-template-homepage"];

  for (var i = 0; i < pages_need_tns.length; i++) {
    if (jQuery(pages_need_tns[i]).length > 0) {

      const sliderExists = document.querySelector('.events-slider');

      if (sliderExists) {

        var slider = tns({
          container: '.event-slider',
          items: 1,
          axis: 'vertical',
          swipeAngle: false,
          speed: 400,
          mouseDrag: false,
          loop: false,
          rewind: false,
          touch: false,
          controlsContainer: '#customize-controls',
          responsive: {
            1024: {
              items: 2,
              rewind: true,
              loop: true,
            },
          }
        });

      }

    }
  }

  // Testimonial on click center page
  jQuery(".testimonial").click(function (event) {
    var $window = jQuery(window),
    $element = jQuery(this),
    elementTop = $element.offset().top,
    elementHeight = $element.height(),
    viewportHeight = $window.height(),
    scrollIt = elementTop - ((viewportHeight - elementHeight) / 2);

    $window.scrollTop(scrollIt);
    // jQuery(this).parent().toggleClass('justify-end');
    jQuery(this).children().find('span.readmore').addClass('dn');
    jQuery(this).children().find('span.close').removeClass('dn');
  });

  jQuery("#testimonail-1, #testimonail-2").on('closed.zf.offcanvas', function (event) {
    console.log('close');
    jQuery(this).parent().parent().find('span.readmore').removeClass('dn');
    jQuery(this).parent().parent().find('span.close').addClass('dn');
  });


  // Footer Contact Us - toggle
  jQuery(".contact-us").click(function (event) {
    jQuery(this).toggleClass('hover');
    if (jQuery("#contact-us").hasClass('open')) {
      jQuery("#contact-us").attr('aria-hidden', 'true');
      jQuery("#contact-us").toggleClass('open');
      setTimeout(function () {
        jQuery("#contact-us").toggleClass('displayblock');
      }, 2000);
    } else {
      jQuery("#contact-us").toggleClass('displayblock');
      setTimeout(function () {
        jQuery("#contact-us").attr('aria-hidden', 'false');
        jQuery("#contact-us").toggleClass('open');
        if (jQuery("#contact-us").hasClass('open')) {
          jQuery("#contact-us #input_1_1").focus();
          // jQuery("html, body").animate({ scrollTop: jQuery(document).height() }, 1000);
          // jQuery("html, body").animate({ scrollBottom: jQuery(document).height() }, 1000);
        }
      }, 150);
    }
    event.preventDefault();
    event.stopPropagation();
  });

  // Gravity Form Placeholder animation
  jQuery('#contact-us input, #contact-us textarea').focus(function () {
    jQuery(this).parents('#contact-us li.gfield').addClass('focused');
  });

  jQuery('#contact-us input, #contact-us textarea').blur(function () {
    var inputValue = jQuery(this).val();
    if (inputValue == "") {
      jQuery(this).removeClass('filled');
      jQuery(this).parents('#contact-us li.gfield').removeClass('focused');
    } else {
      jQuery(this).addClass('filled');
    }
  })

  // TablePress Cleanup
  // tablepress();

  var pages_need_plyr = [".page-template-template-homepage, .page-template-template-about"];

  for (var i = 0; i < pages_need_plyr.length; i++) {
    if (jQuery(pages_need_plyr[i]).length > 0) {
      // const video = document.querySelector('video');
      // const videoOverlay = document.querySelector('.video-container-overlay');

      // video.addEventListener('playing', (event) => {
      //   // console.log('Video is no longer paused');
      //   videoOverlay.classList.remove('db');
      //   videoOverlay.classList.add('dn');
      // });

      // video.addEventListener('pause', (event) => {
      //   // console.log('The Boolean paused property is now true. Either the ' +
      //   //     'pause() method was called or the autoplay attribute was toggled.');
      //   videoOverlay.classList.remove('dn');
      //   videoOverlay.classList.add('db');
      // });
      const player = new Plyr('#player');
    }
  }

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {

  });

  jQuery(window).on('resize', function() {
    var win = jQuery(this); //this = window
    if (win.width() >= 1024) {
      center_page();
    }
  });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
   jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  // jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
  //   if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
  //     jQuery(this).wrap("<div class='widescreen flex-video'/>");
  //   } else {
  //     jQuery(this).wrap("<div class='flex-video'/>");
  //   }
  // });

});

/*
|==========================================================================
| Use this function to fire events after the entire window has loaded,
| including images and CSS files.
|==========================================================================
*/

window.onload = function() {
  AOS.init({

  // Global settings:
  disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  // initClassName: 'aos-init', // class applied after initialization
  // animatedClassName: 'aos-animate', // class applied on animation
  // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  // offset: 120, // offset (in px) from the original trigger point
  // delay: 0, // values from 0 to 3000, with step 50ms
  // duration: 400, // values from 0 to 3000, with step 50ms
  // easing: 'ease', // default easing for AOS animations
  // once: false, // whether animation should happen only once - while scrolling down
  // mirror: false, // whether elements should animate out while scrolling past them
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation);
});

};

function center_page() {
  jQuery('.testimonial[aria-expanded=true]').each(function (i) {
    var $window = jQuery(window),
    $element = jQuery(this),
    elementTop = $element.offset().top,
    elementHeight = $element.height(),
    viewportHeight = $window.height(),
    scrollIt = elementTop - ((viewportHeight - elementHeight) / 2);

    $window.scrollTop(scrollIt);
  });
}